import { Injectable, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/core/services/storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppData implements OnInit {
  static baseUrl = environment.baseUrl;
  static token = new BehaviorSubject(null);
  static isAuthenticated = false;
  static userIdSub$ = new BehaviorSubject(null); // Contain user id
  static userId = null;
  static userInfoSub$ = new BehaviorSubject(null); // Contain user info object
  static userInfoData = null;

  constructor(private storageService: StorageService) {}

  ngOnInit() {
    if (this.storageService.getItem('token') !== null) {
      AppData.token.next(this.storageService.getItem('token'));
      AppData.isAuthenticated = true;
    }

    AppData.token.subscribe((data: string) => {
      this.setToken(data);
    });

    if (this.storageService.getItem('userInfo') !== null) {
      AppData.userInfoSub$.next(this.storageService.getItem('userInfo'));
    }

    AppData.userInfoSub$.subscribe((userObj) => {
      this.storageService.setItem('userInfo', userObj);
      AppData.userInfoData = JSON.parse(JSON.stringify(userObj));
    });
  }
  setToken(token: string) {
    this.storageService.setItem('token', token);
  }

  isAuthenticatedFunction() {
    return !(this.storageService.getItem('token') === null);
  }
}
