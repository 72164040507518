import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
    console.log('storage service');
  }

  setItem(key: string, item: any) {
    localStorage.setItem(key, JSON.stringify(item));
  }
  getItem(key: string) {
    try {
      // console.log('Key:', key);
      // console.log(localStorage.getItem(key));
      return JSON.parse(localStorage.getItem(key));
    } catch (error) {
      console.log('Error: ', error);
      return null;
    }
  }
  clearStorage() {
    localStorage.clear();
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }
}
